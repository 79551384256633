<template>
    <div>
        <v-form lazy-validation ref="salesOrderItemsForm">
            <v-data-table
                :disable-pagination="disablePagination"
                :items-per-page="itemsPerPage"
                :hide-default-footer="disablePagination"
                :headers="headers"
                :height="SalesOrderItems.length < 2 ? '200px' : null"
                :items="SalesOrderItems"
                :show-select="showSelect"
                calculate-widths
                class="mt-3 appic-table-light specification-table"
                dense
                disable-sort
                hide-default-header
                item-key="index"
                v-model="allSelectedItems"
            >
                <template v-slot:top>
                    <v-switch :true-value="true" v-model="editByDialog" dense hide-details="auto" :label="$t('message.editByDialog')"></v-switch>
                </template>
                <template v-slot:header="{ props: { headers, everyItem, someItems }, on: headerOn }">
                    <tr>
                        <th class="grey lighten-3 pa-1 text-center width-1-pct">{{ headers[0].text }}</th>
                        <th class="grey lighten-3 pa-1 text-left">{{ headers[1].text }}</th>
                        <th class="grey lighten-3 pa-1 text-left width-35-pct">
                            <v-row no-gutters>
                                <v-col cols="6">{{ $t('message.product') }}</v-col>
                                <v-col cols="6" class="pl-1">{{ $t('message.spec') }}</v-col>
                            </v-row>
                        </th>
                        <th class="grey lighten-3 pa-1 text-left width-30-pct">
                            <v-row no-gutters>
                                <v-col cols="6">{{ $t('message.size') }}</v-col>
                                <v-col cols="6" class="pl-1">{{ $t('message.quantity') }}</v-col>
                            </v-row>
                        </th>
                        <template v-if="editByDialog">
                            <th class="grey lighten-3 pa-1 text-center width-1-pct">
                                <template v-if="parseInt(SalesOrder__issigned) !== 1">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-simple-checkbox
                                                :value="everyItem"
                                                :indeterminate="someItems && !everyItem"
                                                v-on="on"
                                                v-bind="attrs"
                                                @input="headerOn['toggle-select-all']"
                                            />
                                        </template>
                                        <span>{{ $t('message.selectDeselectPriceEdit') }}</span>
                                    </v-tooltip>
                                </template>
                            </th>
                            <th class="grey lighten-3 pa-1 text-left width-13-pct">
                                <div class="d-flex flex-row align-center justify-space-between">
                                    <span>{{ headers[5].text }}</span>
                                    <v-btn v-if="parseInt(SalesOrder__issigned) !== 1" small class="mr-1" @click.stop="openEditPricesDialog">{{ $t('message.editPrices') }}</v-btn>
                                </div>
                            </th>
                            <th class="grey lighten-3 pa-1 text-left width-15-pct">{{ headers[6].text }}</th>
                            <th class="grey lighten-3 pa-1 text-left width-5-pct">{{ headers[7].text }}</th>
                            <th class="grey lighten-3 pa-1 text-center width-1-pct">{{ headers[8].text }}</th>
                        </template>
                        <template v-else>
                            <th class="grey lighten-3 pa-1 text-left width-20-pct">{{ headers[5].text }}</th>
                            <th class="grey lighten-3 pa-1 text-left width-10-pct">{{ headers[6].text }}</th>
                            <th class="grey lighten-3 pa-1 text-left width-5-pct">{{ headers[7].text }}</th>
                            <th class="grey lighten-3 pa-1 text-center width-1-pct">{{ headers[8].text }}</th>
                        </template>
                    </tr>
                </template>
                <template v-slot:item.lineNumber="{ item }">
                    <div class="text-center mt-2 font-16px">{{ item.lineNumber }}</div>
                </template>
                <template v-slot:item.Stock.bundleno="{ item }">
                    <div class="font-weight-bold mt-2 text-no-wrap font-16px">
                        <span v-if="parseInt(SalesOrder__product_type) === 1">{{ item.Stock.bundleno }}</span>
                        <span v-if="parseInt(SalesOrder__product_type) === 2">{{ item.Stock.wh_pallet_no }}</span>
                    </div>
                    <v-btn
                        small
                        @click="openEditBundleDialog( item.index )"
                        v-if="editByDialog && parseInt(SalesOrder__issigned) !== 1 && item.type === 'default'"
                    >
                        <span v-if="parseInt(SalesOrder__product_type) === 1">{{ $t('message.editBundleInfo') }}</span>
                        <span v-if="parseInt(SalesOrder__product_type) === 2">{{ $t('message.editPallet') }}</span>
                    </v-btn>
                </template>
                <template v-slot:item.Stock.productgroup_id="{ item }">
                    <v-row no-gutters v-if="editByDialog && item.type === 'default'">
                        <v-col cols="6">
                            <template>
                                <v-row no-gutters class="pt-1">
                                    <v-col cols="5" class="font-16px">{{ $t('message.product') + ":" }}</v-col>
                                    <v-col cols="7" class="font-16px input-box">{{ item.Productgroup.title }}</v-col>
                                </v-row>
                                <v-row no-gutters class="pt-1">
                                    <v-col cols="5" class="font-16px">{{ $t('message.species') + ":" }}</v-col>
                                    <v-col cols="7" class="font-16px input-box">{{ item.Species.title }}</v-col>
                                </v-row>
                                <v-row no-gutters class="pt-1 mb-1">
                                    <v-col cols="5" class="font-16px">{{ $t('message.certification') + ":" }}</v-col>
                                    <v-col cols="7" class="font-16px input-box">{{ item.Certification.title }}</v-col>
                                </v-row>
                            </template>
                        </v-col>
                        <v-col cols="6" class="pl-1">
                            <template>
                                <v-row no-gutters class="pt-1">
                                    <v-col cols="5" class="font-16px">{{ $t('message.grade') + ":" }}</v-col>
                                    <v-col cols="7" class="font-16px input-box">{{ item.Grade.title }}</v-col>
                                </v-row>
                                <v-row no-gutters class="pt-1">
                                    <v-col cols="5" class="font-16px">{{ $t('message.spec') + ":" }}</v-col>
                                    <v-col cols="7" class="font-16px input-box">{{ item.Spec.title }}</v-col>
                                </v-row>
                                <v-row no-gutters class="pt-1 mb-1" v-if="parseInt(SalesOrder__product_type) === 1">
                                    <v-col cols="5" class="font-16px">{{ $t('message.mc') + ":" }}</v-col>
                                    <v-col cols="7" class="font-16px input-box">{{ item.Mc.title }}</v-col>
                                </v-row>
                            </template>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="editByDialog && item.type === 'othercharge'">
                        <v-col cols="12">
                            <v-text-field
                                :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                :value="item.Stock.size"
                                autocomplete="off"
                                class="custom force-text-left pa-1 bg-lightgrey"
                                clearable
                                dense
                                hide-details="auto"
                                @change="updateSalesOrderItemRowHandle( item.index, 'Stock.size',$event)"
                            />
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="!editByDialog">
                        <v-col cols="6">
                            <template v-if="item.type === 'default'">
                                <div class="d-flex flex-row align-center">
                                    <div style="min-width: 100px !important;" class="font-16px">{{ $t('message.product') + ":" }}</div>
                                    <v-autocomplete
                                        :id="item.index + 'product'"
                                        :items=allProductGroups
                                        :placeholder="item.Stock.productgroup_id ? '' : $t('message.required')"
                                        :rules="[...validationRules.required]"
                                        :value="item.Stock.productgroup_id"
                                        autocomplete="off"
                                        class="custom pa-1 bg-lightgrey"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-value="ProductGroup.id"
                                        item-text="ProductGroup.title"
                                        @input="updateSalesOrderItemRowHandle( item.index, 'Stock.productgroup_id',$event)"
                                    />
                                </div>
                                <div class="d-flex flex-row align-center">
                                    <div style="min-width: 100px !important;" class="font-16px">{{ $t('message.species') + ":" }}</div>
                                    <v-autocomplete
                                        :id="item.index + 'species'"
                                        :items="item.Options.species"
                                        :placeholder="item.Options.species.length > 0 ? $t('message.select') : $t('message.selectProductFirst')"
                                        :rules="[...validationRules.required]"
                                        :value="item.Stock.species_id"
                                        autocomplete="off"
                                        class="custom pa-1 bg-lightgrey"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-value="Species.id"
                                        item-text="Species.title"
                                        return-object
                                        @input="updateSalesOrderItemRowHandle( item.index, 'Stock.species_id',$event)"
                                    />
                                </div>
                                <div class="d-flex flex-row align-center">
                                    <div style="min-width: 100px !important;" class="font-16px">{{ $t('message.certification') + ":" }}</div>
                                    <v-select
                                        :id="item.index + 'certification'"
                                        :items="allCertifications"
                                        :placeholder="$t('message.select')"
                                        :rules="[...validationRules.requiredWithZero]"
                                        :value="item.Stock.certification_id"
                                        autocomplete="off"
                                        class="custom pa-1 bg-lightgrey"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-text="Certification.title"
                                        item-value="Certification.id"
                                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.certification_id',$event)"
                                    />
                                </div>
                            </template>
                            <template v-if="item.type === 'othercharge'">
                                <v-text-field
                                    :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                    :value="item.Stock.size"
                                    autocomplete="off"
                                    class="custom force-text-left pa-1 bg-lightgrey"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    @change="updateSalesOrderItemRowHandle( item.index, 'Stock.size',$event)"
                                />
                            </template>
                        </v-col>
                        <v-col cols="6" class="pl-1">
                            <template v-if="item.type === 'default'">
                                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                                    <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.grade') + ":" }}</div>
                                    <v-autocomplete
                                        :id="item.index + 'grade'"
                                        :items="item.Options.grades"
                                        :placeholder="$t('message.startTypingToSearch')"
                                        :value="item.Stock.grade_id"
                                        autocomplete="off"
                                        class="custom pa-1 bg-lightgrey"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-text="Grade.title"
                                        item-value="Grade.id"
                                        return-object
                                        @focus="currentRowIndex = item.index"
                                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.grade_id',$event)"
                                    >
                                        <template v-slot:selection="{ item }">
                                            <div class="text-wrap" style="width: 100%">{{ item.Grade.title }}</div>
                                        </template>
                                    </v-autocomplete>
                                </div>
                                <div class="d-flex flex-row align-center">
                                    <div style="width: 50px" class="font-16px">{{ $t('message.spec') + ":" }}</div>
                                    <v-combobox
                                        :id="item.index + 'spec'"
                                        :items="item.Options.specs"
                                        :placeholder="item.Options.specs.length > 0 ? $t('message.select') : $t('message.selectProductFirst')"
                                        :value="item.Spec.title"
                                        class="custom pa-1 bg-lightgrey"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-value="Spec.id"
                                        item-text="Spec.title"
                                        return-object
                                        @focus="currentRowIndex = item.index"
                                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.spec_id',$event)"
                                    />
                                </div>
                                <div class="d-flex flex-row align-center" v-if="item.Stock.productgroup_id != 15">
                                    <div style="width: 50px" class="font-16px">{{ $t('message.mc') + ":" }}</div>
                                    <v-select
                                        :id="item.index + 'mc'"
                                        :items="allMoistureContent"
                                        :value="item.Stock.mc_id"
                                        class="custom pa-1 bg-lightgrey"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-text="Mc.title"
                                        item-value="Mc.id"
                                        @focus="currentRowIndex = item.index"
                                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.mc_id',$event)"
                                    />
                                </div>
                            </template>
                        </v-col>
                    </v-row>
                    <template v-if="!editByDialog">
                        <div class="d-flex flex-row align-center" style="height: 35px" v-if="item.type == 'default'">
                            <div class="font-16px">{{ $t('message.specificationAsShownSalesOrder') + ": " + getUiDescription(item) }}</div>
                        </div>
                    </template>
                </template>
                <template v-slot:item.Stock.size_id="{ item }">
                    <template v-if="item.type === 'default'">
                        <v-row no-gutters v-if="editByDialog">
                            <v-col cols="6">
                                <v-row no-gutters class="pt-1">
                                    <v-col cols="5" class="font-16px">{{ $t('message.thickness') + ":" }}</v-col>
                                    <v-col cols="7" class="font-16px input-box">{{ getThickness(item.Stock.thickness_id) }}</v-col>
                                </v-row>
                                <v-row no-gutters class="pt-1">
                                    <v-col cols="5" class="font-16px">{{ $t('message.width') + ":" }}</v-col>
                                    <v-col cols="7" class="font-16px input-box">{{ item.Stock.width }}</v-col>
                                </v-row>
                                <v-row no-gutters class="pt-1 mb-1">
                                    <v-col cols="5" class="font-16px">{{ $t('message.length') + ":" }}</v-col>
                                    <v-col cols="7" class="font-16px input-box">{{ item.Stock.length }}</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6">
                                <v-row no-gutters class="pt-1">
                                    <v-col cols="5" class="font-16px pl-1">
                                        <span v-if="parseInt(SalesOrder__product_type) === 1">{{ $t('message.pcs') + ":" }}</span>
                                        <span v-if="parseInt(SalesOrder__product_type) === 2">{{ $t('message.sheets') + ":" }}</span>
                                    </v-col>
                                    <v-col cols="7" class="font-16px input-box text-right">
                                        <span v-if="parseInt(SalesOrder__product_type) === 1">{{ item.Stock.piece }}</span>
                                        <span v-if="parseInt(SalesOrder__product_type) === 2">{{ item.Stock.sheets }}</span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="pt-1">
                                    <v-col cols="5" class="font-16px pl-1">{{ $t('message.qty') + ":" }}</v-col>
                                    <v-col cols="7" class="font-16px input-box text-right">{{ item.Stock.volume }}</v-col>
                                </v-row>
                                <v-row no-gutters class="pt-1 mb-1">
                                    <v-col cols="5" class="font-16px pl-1">{{ $t('message.unit') + ":" }}</v-col>
                                    <v-col cols="7" class="font-16px input-box text-right">{{ item.Soldmeasurement.title }}</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row no-gutters v-if="!editByDialog">
                            <v-col cols="6">
                                <div class="d-flex flex-row align-center">
                                    <div style="min-width: 100px !important;" class="font-16px">{{ $t('message.thickness') + ":" }}</div>
                                    <v-autocomplete
                                        :id="item.index + 'thickness'"
                                        :items="allThickness"
                                        :placeholder="$t('message.select')"
                                        :value="item.Stock.thickness_id"
                                        class="custom pa-1 bg-lightgrey"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-text="Thickness.title"
                                        item-value="Thickness.id"
                                        @focus="currentRowIndex = item.index"
                                        @click="filterThickness(SalesOrder__ms_id)"
                                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.thickness_id',$event)"
                                    >
                                        <template v-slot:item="{item}">{{ item.Thickness.title + (item.Thickness.suffix != null ? item.Thickness.suffix : '') }}</template>
                                        <template v-slot:selection="{item}">{{ item.Thickness.title + (item.Thickness.suffix != null ? item.Thickness.suffix : '') }}</template>
                                    </v-autocomplete>
                                </div>
                                <div class="d-flex flex-row align-center">
                                    <div style="min-width: 100px !important;" class="font-16px">{{ $t('message.width') + ":" }}</div>
                                    <v-text-field
                                        :id="item.index + 'width'"
                                        :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                        :placeholder="$t('message.freeText')"
                                        :value="item.Stock.width"
                                        class="custom pa-1 bg-lightgrey force-text-left"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.width',$event)"
                                    />
                                </div>
                                <div class="d-flex flex-row align-center">
                                    <div style="min-width: 100px !important;" class="font-16px">{{ $t('message.length') + ":" }}</div>
                                    <v-text-field
                                        :id="item.index + 'length'"
                                        :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                        :placeholder="$t('message.freeText')"
                                        :value="item.Stock.length"
                                        autocomplete="off"
                                        clearable
                                        class="custom pa-1 bg-lightgrey force-text-left"
                                        dense
                                        hide-details="auto"
                                        type="text"
                                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.length',$event)"
                                    />
                                </div>
                            </v-col>
                            <v-col cols="6" class="pl-1">
                                <div class="d-flex flex-row align-center">
                                    <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.pcs') + ":" }}</div>
                                    <v-text-field
                                        :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                        :value="item.Stock.piece"
                                        autocomplete="off"
                                        class="custom pa-1 bg-lightgrey"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.piece',$event)"
                                    />
                                </div>
                                <div class="d-flex flex-row align-center">
                                    <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.qty') + ":" }}</div>
                                    <v-text-field
                                        :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                        :value="item.Stock.volume"
                                        autocomplete="off"
                                        class="custom pa-1 bg-lightgrey"
                                        clearable
                                        dense
                                        hide-details
                                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.volume',$event)"
                                    />
                                </div>
                                <div class="d-flex flex-row align-center">
                                    <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.unit') + ":" }}</div>
                                    <v-select
                                        :items="uofmQty"
                                        :value="item.Stock.soldmeasurement_id"
                                        autocomplete="off"
                                        class="custom pa-1 bg-lightgrey"
                                        dense
                                        hide-details="auto"
                                        item-value="Measurement.id"
                                        item-text="Measurement.title"
                                        reverse
                                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.soldmeasurement_id',$event)"
                                    />
                                </div>
                            </v-col>
                        </v-row>
                        <div class="d-flex flex-row align-center" style="height: 35px" v-if="!editByDialog">
                            <div class="font-16px">{{ $t('message.sizeAsShownSalesOrder') + ": " + (item.Stock.size ? item.Stock.size : '') }}</div>
                        </div>
                    </template>
                </template>
                <template v-slot:item.data-table-select="{ item, isSelected, select }">
                    <template v-if="item.type === 'default' && parseInt(SalesOrder__issigned) !== 1">
                        <v-simple-checkbox :value="isSelected" @input="select($event)"/>
                    </template>
                    <template v-else>
                        <div class="ml-2 mt-1 font-16px">{{ $t('message.na') }}</div>
                    </template>
                </template>
                <template v-slot:item.Stock.costprice="{ item }">
                    <template v-if="item.type === 'default'">
                        <template v-if="editByDialog">
                            <v-row no-gutters class="pt-1">
                                <v-col cols="3" class="font-16px">
                                    <span v-if="parseInt(SalesOrder__product_type) === 1">{{ "CP:" }}</span>
                                    <span v-if="parseInt(SalesOrder__product_type) === 2">{{ "GAC:" }}</span>
                                </v-col>
                                <v-col cols="6" class="font-16px input-box text-right">
                                    <div v-if="parseInt(SalesOrder__product_type) === 1">{{ formatThisNumber(item.Stock.costprice, currencyFormatPrecision) }}</div>
                                    <div v-if="parseInt(SalesOrder__product_type) === 2">{{ formatThisNumber(item.Stock.adjusted_grading_cost, currencyFormatPrecision) }}</div>
                                </v-col>
                                <v-col cols="3" class="font-16px input-box">{{ getCurrencyCode(item.Stock.StockOrder.currency_id)  }}</v-col>
                            </v-row>
<!--                            <v-row no-gutters class="pt-1">-->
<!--                                <v-col cols="5" class="font-16px">{{ $t('message.importDuty') + ":" }}</v-col>-->
<!--                                <v-col cols="5" class="font-16px input-box text-right">{{ formatThisNumber(item.Stock.importduty, currencyFormatPrecision) }}</v-col>-->
<!--                                <v-col cols="2" class="font-16px input-box">{{ getCurrencyCode(item.Stock.StockOrder.currency_id)  }}</v-col>-->
<!--                            </v-row>-->
<!--                            <v-row no-gutters class="pt-1">-->
<!--                                <v-col cols="5" class="font-16px">{{ $t('message.storageCost') + ":" }}</v-col>-->
<!--                                <v-col cols="5" class="font-16px input-box text-right">{{ formatThisNumber(item.Stock.warehousecost, currencyFormatPrecision) }}</v-col>-->
<!--                                <v-col cols="2" class="font-16px input-box">{{ getCurrencyCode(item.Stock.StockOrder.currency_id)  }}</v-col>-->
<!--                            </v-row>-->
<!--                            <v-row no-gutters class="pt-1 mb-1">-->
<!--                                <v-col cols="5" class="font-16px">{{ $t('message.freightCost') + ":" }}</v-col>-->
<!--                                <v-col cols="5" class="font-16px input-box text-right">{{ formatThisNumber(item.Stock.forwardingcharge, currencyFormatPrecision) }}</v-col>-->
<!--                                <v-col cols="2" class="font-16px input-box">{{ getCurrencyCode(item.Stock.StockOrder.currency_id)  }}</v-col>-->
<!--                            </v-row>-->
                        </template>
                        <template v-if="!editByDialog">
                            <div class="d-flex flex-row align-center">
                                <div style="min-width: 100px !important;" class="font-16px">
                                    <span v-if="parseInt(SalesOrder__product_type) === 1">{{ $t('message.buyingPrice') + ":" }}</span>
                                    <span v-if="parseInt(SalesOrder__product_type) === 2">{{ "GAC:" }}</span>
                                </div>
                                <v-text-field
                                    :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                    :value="item.Stock.costprice"
                                    autocomplete="off"
                                    class="custom pa-1 bg-lightgrey"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    @change="updateSalesOrderItemRowHandle( item.index, 'Stock.costprice',$event)"
                                    v-if="parseInt(SalesOrder__product_type) === 1"
                                />
                                <v-text-field
                                    :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                    :value="item.Stock.adjusted_grading_cost"
                                    autocomplete="off"
                                    class="custom pa-1 bg-lightgrey"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    @change="updateSalesOrderItemRowHandle( item.index, 'Stock.adjusted_grading_cost',$event)"
                                    v-if="parseInt(SalesOrder__product_type) === 2"
                                />
                                <div class="ml-1 mt-1 font-16px">{{ getCurrencyCode(item.Stock.StockOrder.currency_id) }}</div>
                            </div>
                            <div class="d-flex flex-row align-center">
                                <div style="min-width: 100px !important;" class="font-16px">{{ $t('message.importDuty') + ":" }}</div>
                                <v-text-field
                                        :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                        :value="item.Stock.importduty"
                                        autocomplete="off"
                                        class="custom pa-1 bg-lightgrey"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.importduty',$event)"
                                />
                                <div class="ml-1 mt-1 font-16px">{{ getCurrencyCode(item.Stock.StockOrder.currency_id) }}</div>
                            </div>
                            <div class="d-flex flex-row align-center">
                                <div style="min-width: 100px !important;" class="font-16px">{{ $t('message.storageCost') + ":" }}</div>
                                <v-text-field
                                        :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                        :value="item.Stock.warehousecost"
                                        autocomplete="off"
                                        class="custom pa-1 bg-lightgrey"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.warehousecost',$event)"
                                />
                                <div class="ml-1 mt-1 font-16px">{{ getCurrencyCode(item.Stock.StockOrder.currency_id) }}</div>
                            </div>
                            <div class="d-flex flex-row align-center pb-2">
                                <div style="min-width: 100px !important;" class="font-16px">{{ $t('message.freightCost') + ":" }}</div>
                                <v-text-field
                                        :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                        :value="item.Stock.forwardingcharge"
                                        autocomplete="off"
                                        class="custom pa-1 bg-lightgrey"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.forwardingcharge',$event)"
                                />
                                <div class="ml-1 mt-1 font-16px">{{ getCurrencyCode(item.Stock.StockOrder.currency_id) }}</div>
                            </div>
                        </template>
                    </template>
                </template>
                <template v-slot:item.Stock.sellingprice="{ item }">
                    <div v-if="editByDialog">
                        <v-row no-gutters class="pt-1" v-if="item.type === 'default'">
                            <v-col cols="8" class="font-16px input-box text-right">{{ formatThisNumber(item.Stock.sellingprice,currencyFormatPrecision) }}</v-col>
                            <v-col cols="3" class="font-16px input-box">{{ getCurrencyCode(SalesOrder__currency_id) }}</v-col>
                        </v-row>
                        <template v-if="item.type === 'othercharge'">
                            <div class="d-flex flex-row align-center">
                                <v-text-field
                                    :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                    :value="item.Stock.sellingprice"
                                    autocomplete="off"
                                    class="custom pa-1 bg-lightgrey"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    @change="updateSalesOrderItemRowHandle( item.index, 'Stock.sellingprice',$event)"
                                />
                                <div class="ml-1 mt-1 font-16px">{{ getCurrencyCode(SalesOrder__currency_id) }}</div>
                            </div>
                        </template>
                    </div>
                    <div class="d-flex flex-row align-center" v-if="!editByDialog">
                        <v-text-field
                                :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                :value="item.Stock.sellingprice"
                                autocomplete="off"
                                class="custom pa-1 bg-lightgrey"
                                clearable
                                dense
                                hide-details="auto"
                                @change="updateSalesOrderItemRowHandle( item.index, 'Stock.sellingprice',$event)"
                        />
                        <div class="ml-1 mt-1 font-16px">{{ getCurrencyCode(SalesOrder__currency_id) }}</div>
                    </div>
                </template>
                <template v-slot:item.Stock.amount="{ item }">
                    <div class="mt-2 text-no-wrap text-right align-center font-16px" style="height: 30px">{{ formatThisNumber(item.Stock.amount, findCurrencyPrecision(SalesOrder__currency_id)) + ' ' + getCurrencyCode(SalesOrder__currency_id) }}</div>
                </template>
                <template v-slot:item.Stock.id="{ item }">
                    <div class="ml-2 mt-1" v-if="parseInt(SalesOrder__orderstatus_id) === 1 && (SalesOrder__issigned == null || parseInt(SalesOrder__issigned) === 0)">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon @click="deleteItem(item.index, item.Stock.id)" v-on="on">
                                    <v-icon color="grey">delete</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.deleteThisItem') }}</span>
                        </v-tooltip>
                    </div>
                    <div v-else class="mt-2 text-center font-weight-bold align-center font-16px" style="height: 30px">{{ $t('message.na') }}</div>
                </template>
                <template v-slot:footer>
                    <div class="pt-0 d-flex justify-space-between">
                        <div class="col-4 col-lg-4 col-md-3 pt-5 px-0 d-flex justify-start">
                            <v-btn
                                    @click="openSelectStockDialog()"
                                    color="success"
                                    small
                                    v-if="parseInt(SalesOrder__orderstatus_id) === 1 && parseInt(SalesOrder__issigned) === 0"
                            >{{ SalesOrderItems.length === 0 ? $t('message.addItem') : $t('message.addMore') }}
                            </v-btn>
                            <v-btn
                                    @click="addOtherChargesItemRow(statePrefix)"
                                    class="ml-2"
                                    color="success"
                                    small
                                    v-if="parseInt(SalesOrder__orderstatus_id) === 1 && parseInt(SalesOrder__issigned) === 0"
                            >{{ $t('message.addOtherCharges') }}
                            </v-btn>
                            <v-btn
                                    :loading="resettingSalesOrderItems"
                                    class="ml-2"
                                    color="default"
                                    small
                                    v-if="updateMode === false"
                                    @click="resetItems"
                            ><v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                            </v-btn>
                        </div>
                        <div class="dense-inputs col-8 col-lg-8 col-md-9 pa-0">
                            <v-row justify="end">
                                <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pr-3 pb-0">
                                    <template v-if="parseInt(SalesOrder__product_type) === 1">
                                        <div class="text-right font-weight-black">{{ $t('message.totalPieces') }}</div>
                                        <div class="text-right font-weight-black">{{ itemsTotalPieces }}</div>
                                    </template>
                                    <template v-if="parseInt(SalesOrder__product_type) === 2">
                                        <div class="text-right font-weight-black">{{ $t('message.totalSheets') }}</div>
                                        <div class="text-right font-weight-black">{{ itemsTotalSheets}}</div>
                                    </template>
                                </v-col>
                                <v-col cols="2" lg="2" md="2" xs="3" class="px-1 pb-0">
                                    <div class="text-right font-weight-black">{{ $t('message.totalVolAndQty') }}</div>
                                    <div class="text-right font-weight-black d-flex flex-column">
                                        <span v-for="(total,index) in itemsTotalQty" :class="itemsTotalQty.length > 1 && index === 0  ? 'pt-1' : ''">
                                            {{ formatThisNumber(total.Total.qty,uofmPrecision(total.Total.uofm)) + ' '+ total.Total.uofm }}
                                        </span>
                                    </div>
                                </v-col>
                                <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                                    <div class="text-right font-weight-black">{{ $t('message.currency') }}</div>
                                    <v-container class="ma-0 pa-0">
                                        <v-row no-gutters>
                                            <v-col cols="9" offset="3">
                                                <v-select
                                                        :items="allCurrencies"
                                                        class="custom py-0 mt-0 bg-lightgrey"
                                                        hide-details
                                                        item-text="Currency.code"
                                                        item-value="Currency.id"
                                                        reverse
                                                        v-model="SalesOrder__currency_id"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-col>
                                <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                                    <div class="text-right font-weight-black">
                                        <span v-if="parseInt(SalesOrder__currency_id) === 2">{{ getCurrencyCode(Office__currency_id) + ':' + getCurrencyCode(2) + ' ' + $t('message.exRate') }}</span>
                                        <span v-else>{{ getCurrencyCode(SalesOrder__currency_id) + ':' + getCurrencyCode(2) + ' ' + $t('message.exRate') }}</span>
                                    </div>
                                    <v-container class="ma-0 pa-0">
                                        <v-text-field
                                                :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                                :value="SalesOrder__gstrmrate"
                                                class="custom py-1 pb-2 mt-0 bg-lightgrey"
                                                dense
                                                hide-details
                                                @change="SalesOrder__gstrmrate = $event"
                                        />
                                    </v-container>
                                </v-col>
                                <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pr-3 pb-0">
                                    <div class="text-right font-weight-black">{{ $t('message.totalAmount') + '(' + getCurrencyCode(2) +')' }}</div>
                                    <div class="text-right font-weight-black">
                                        <span v-if="parseInt(SalesOrder__currency_id) === 2">{{ formatThisNumber((itemsTotalAmount),currencyFormatPrecision) }}</span>
                                        <span v-else>{{ formatThisNumber((itemsTotalAmount / SalesOrder__gstrmrate),currencyFormatPrecision) }}</span>
                                    </div>
                                </v-col>
                                <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pr-3 pb-0">
                                    <div class="text-right font-weight-black">
                                        <span v-if="parseInt(SalesOrder__currency_id) === 2">{{ $t('message.totalAmount') + '(' + getCurrencyCode(Office__currency_id) +')' }}</span>
                                        <span v-else>{{ $t('message.totalAmount') + '(' + getCurrencyCode(SalesOrder__currency_id) +')' }}</span>
                                    </div>
                                    <div class="text-right font-weight-black">
                                        <span v-if="parseInt(SalesOrder__currency_id) === 2">{{ formatThisNumber(itemsTotalAmount * SalesOrder__gstrmrate,currencyFormatPrecision) }}</span>
                                        <span v-else>{{ formatThisNumber(itemsTotalAmount,currencyFormatPrecision) }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </template>
            </v-data-table>
        </v-form>
        <SalesOrderBundleUpdate
            :sales-order-currency-id="SalesOrder__currency_id"
            :dialog.sync="editBundleDialog"
            :item-index="selectedBundleIndex"
            :type="SalesOrder__product_type"
            :update-mode="updateMode"
            :ms-id="SalesOrder__ms_id"
            @dialog-closed="editBundleDialogClosed"
            @update-done="bundleUpdated"
        />
        <SalesOrderBundlePriceUpdate
            :sales-order-currency-id="SalesOrder__currency_id"
            :dialog.sync="editPricesDialog"
            :selected-items="selectedItems"
            :type="SalesOrder__product_type"
            :update-mode="updateMode"
            @dialog-closed="editPricesDialogClosed"
            @update-done="bundlePriceUpdated"
        />
        <v-dialog
                persistent
                scrollable
                transition="dialog-top-transition"
                v-model="selectStockDialog"
                width="100%"
                max-width="1300px !important"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.selectWarehouseStock') }}</v-card-title>
                <v-card-text class="pa-3">
                    <v-overlay
                        :value="loading.warehouseStock"
                        absolute
                        opacity="0.15"
                    >
                        <v-row>
                            <v-col class="text-center">
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                                />
                            </v-col>
                        </v-row>
                    </v-overlay>
                    <v-data-table
                        :footer-props="{
                            itemsPerPageOptions: [10,20,-1],
                            showCurrentPage: true,
                            showFirstLastPage: true
                        }"
                        :headers="stockHeaders"
                        :items="filteredWarehouseStocks"
                        :search="searchTerm"
                        :options.sync="tableOptions"
                        :sort-by="['Stock.age']"
                        calculate-widths
                        class="appic-table-light specification-table warehouse-stocks"
                        dense
                        item-key="Stock.id"
                        show-select
                        v-model="allSelectedBundles"
                    >
                        <template v-slot:top>
                            <v-row no-gutters>
                                <v-col cols="12" class="d-flex flex-row col-lg-12 col-sm-12 align-center pb-3">
                                    <v-text-field
                                            :placeholder="$t('message.poBundle')"
                                            :value="searchTerm"
                                            class="filter-width-150 force-text-left"
                                            clearable
                                            dense
                                            hide-details="auto"
                                            append-icon="search"
                                            solo
                                            @change="searchTerm = $event"
                                    />
                                    <v-autocomplete
                                            :items="filterOptions.suppliers"
                                            :placeholder="$t('message.supplier')"
                                            :value="filterValues.supplier"
                                            autocomplete="off"
                                            class="filter-width-150 ml-2"
                                            clearable
                                            dense
                                            hide-details="auto"
                                            multiple
                                            solo
                                            @change="filterList('supplier',$event)"
                                    />
                                    <v-autocomplete
                                            :items="filterOptions.products"
                                            :placeholder="$t('message.product')"
                                            :value="filterValues.product"
                                            autocomplete="off"
                                            class="filter-width-150 ml-2"
                                            clearable
                                            dense
                                            hide-details="auto"
                                            multiple
                                            solo
                                            @change="filterList('product',$event)"
                                    />
                                    <v-autocomplete
                                            :items="filterOptions.species"
                                            :placeholder="$t('message.species')"
                                            :value="filterValues.species"
                                            autocomplete="off"
                                            class="filter-width-150 ml-2"
                                            clearable
                                            dense
                                            hide-details="auto"
                                            multiple
                                            solo
                                            @change="filterList('species',$event)"
                                    />
                                    <v-autocomplete
                                            :items="filterOptions.claims"
                                            :placeholder="$t('message.claim')"
                                            :value="filterValues.claim"
                                            autocomplete="off"
                                            class="filter-width-150 ml-2"
                                            clearable
                                            dense
                                            hide-details="auto"
                                            multiple
                                            solo
                                            @change="filterList('claim',$event)"
                                    />
                                    <v-btn :loading="loading.reset" class="ml-2" @click="resetFilters()">{{ $t('message.clear') }}</v-btn>
                                    <v-spacer></v-spacer>
                                    <span>{{ allSelectedBundles.length + ' bundles selected' }}</span>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.Stock.StockOrder.title="{ item }">
                            <div class="font-sm pt-3 text-no-wrap" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ item.Stock.StockOrder.title }}</del>
                            </div>
                            <div class="font-sm pt-3 text-no-wrap" v-else>{{ item.Stock.StockOrder.title }}</div>
                        </template>
                        <template v-slot:item.Stock.StockOrder.Supplier.code="{ item }">
                            <div class="font-sm pt-3 text-no-wrap" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ item.Stock.StockOrder.Supplier.code }}</del>
                            </div>
                            <div class="font-sm pt-3 text-no-wrap" v-else>{{ item.Stock.StockOrder.Supplier.code }}</div>
                        </template>
                        <template v-slot:item.Stock.StockOrder.stockindate="{ item }">
                            <div class="font-sm pt-3" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ formatDate(item.Stock.StockOrder.stockindate) }}</del>
                            </div>
                            <div class="font-sm pt-3" v-else>{{ formatDate(item.Stock.StockOrder.stockindate) }}</div>
                        </template>
                        <template v-slot:item.Stock.age="{ item }">
                            <template v-if="item.Stock.age !== 'fresh'">
                                <div class="font-sm pt-3" v-if="checkIfSelected(item.Stock.id)">
                                    <del>{{ item.Stock.age }}</del>
                                </div>
                                <div class="font-sm pt-3" v-else>{{ item.Stock.age }}</div>
                            </template>
                        </template>
                        <template v-slot:item.Stock.certification="{ item }">
                            <div class="font-sm pt-3 text-no-wrap" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ item.Stock.certification }}</del>
                            </div>
                            <div class="font-sm pt-3 text-no-wrap" v-else>{{ item.Stock.certification }}</div>
                        </template>
                        <template v-slot:item.Stock.bundleno="{ item }">
                            <div class="font-sm font-weight-bold pt-3 text-no-wrap" v-if="checkIfSelected(item.Stock.id)">
                                <del v-if="parseInt(SalesOrder__product_type) === 1">{{ item.Stock.bundleno }}</del>
                                <del v-if="parseInt(SalesOrder__product_type) === 2">{{ item.Stock.wh_pallet_no }}</del>
                            </div>
                            <div class="font-sm font-weight-bold pt-3 text-no-wrap" v-else>
                                <span v-if="parseInt(SalesOrder__product_type) === 1">{{ item.Stock.bundleno }}</span>
                                <span v-if="parseInt(SalesOrder__product_type) === 2">{{ item.Stock.wh_pallet_no }}</span>
                            </div>
                        </template>
                        <template v-slot:item.Product.title="{ item }">
                            <div class="font-sm pt-3 text-no-wrap" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ item.Product.title }}</del>
                            </div>
                            <div class="font-sm pt-3 text-no-wrap" v-else>{{ item.Product.title }}</div>
                        </template>
                        <template v-slot:item.Species.title="{ item }">
                            <div class="font-sm pt-3 text-no-wrap" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ item.Species.title }}</del>
                            </div>
                            <div class="font-sm pt-3 text-no-wrap" v-else>{{ item.Species.title }}</div>
                        </template>
                        <template v-slot:item.Grade.title="{ item }">
                            <div class="font-sm pt-3 text-no-wrap" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ item.Grade.title }}</del>
                            </div>
                            <div class="font-sm pt-3 text-no-wrap" v-else>{{ item.Grade.title }}</div>
                        </template>
                        <template v-slot:item.Stock.thickness="{ item }">
                            <div class="font-xs pt-3 text-no-wrap" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ item.Stock.thickness }}</del>
                            </div>
                            <div class="font-xs pt-3 text-no-wrap" v-else>{{ item.Stock.thickness }}</div>
                        </template>
                        <template v-slot:item.Stock.width="{ item }">
                            <div class="font-xs pt-3 text-no-wrap" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ item.Stock.width }}</del>
                            </div>
                            <div class="font-xs pt-3 text-no-wrap" v-else>{{ item.Stock.width }}</div>
                        </template>
                        <template v-slot:item.Stock.length="{ item }">
                            <div class="font-xs pt-3 text-no-wrap" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ item.Stock.length }}</del>
                            </div>
                            <div class="font-xs pt-3 text-no-wrap" v-else>{{ item.Stock.length }}</div>
                        </template>
                        <template v-slot:item.Stock.piece="{ item }">
                            <div class="font-sm pt-3 float-right" v-if="checkIfSelected(item.Stock.id)">
                                <del v-if="parseInt(SalesOrder__product_type) === 1">{{ item.Stock.piece }}</del>
                                <del v-if="parseInt(SalesOrder__product_type) === 2">{{ item.Stock.sheets }}</del>
                            </div>
                            <div class="font-sm pt-3 float-right" v-else>
                                <span v-if="parseInt(SalesOrder__product_type) === 1">{{ item.Stock.piece }}</span>
                                <span v-if="parseInt(SalesOrder__product_type) === 2">{{ item.Stock.sheets }}</span>
                            </div>
                        </template>
                        <template v-slot:item.Stock.volume="{ item }">
                            <div class="font-sm pt-3 float-right text-no-wrap" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ item.Stock.volume }}</del>
                            </div>
                            <div class="font-sm pt-3 float-right text-no-wrap" v-else>{{ item.Stock.volume }}</div>
                        </template>
                        <template v-slot:item.Stock.StockOrder.Quantity.uofm="{ item }">
                            <div class="font-sm pt-3 float-right text-no-wrap" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ item.Stock.StockOrder.Quantity.uofm }}</del>
                            </div>
                            <div class="font-sm pt-3 float-right text-no-wrap" v-else>{{ item.Stock.StockOrder.Quantity.uofm }}</div>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        class="ml-3"
                        color="default"
                        small
                        @click="selectStockDialog = false"
                    >{{ $t('message.backToEditing') }}</v-btn>
                    <v-btn
                        :loading="loading.addBundle"
                        small
                        @click="loading.addBundle = true; addBundlesToSalesOrder()"
                    >{{ $t('message.addItems') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {mapFields} from "vuex-map-fields";
import {formatDate, generateDimension, generateSpec, numberFormat} from "Helpers/helpers";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {api} from "Api";
import {log} from "Helpers/helpers";
import {validationRules} from "@/store/modules/appic/constants";
const SalesOrderBundleUpdate = () => import("Components/Appic/SalesOrderBundleUpdate.vue");
const SalesOrderBundlePriceUpdate = () => import("Components/Appic/SalesOrderBundlePriceUpdate.vue");

export default {
    name: "SalesOrderItemListV3",
    components: {SalesOrderBundleUpdate, SalesOrderBundlePriceUpdate},
    props: ['updateMode'],
    data () {
        return {
            allItemsSelected: false,
            allSelectedItems: [],
            allSelectedBundles: [],
            bundleTitle: this.$t('message.bundle'),
            costPriceTitle: this.$t('message.costPrice'),
            currentRowIndex: 0,
            dialogs: {
                confirm:  false,
                confirm_message: "",
                error: false,
                error_message: ""
            },
            disablePagination: true,
            editBundleDialog: false,
            editByDialog: false,
            editPricesDialog: false,
            filteredWarehouseStocks: [],
            filteredThickness: [],
            filterOptions: {
                claims: [],
                products: [],
                species: [],
                suppliers: []
            },
            filterValues: {
                claim: null,
                product: null,
                species: null,
                supplier: null
            },
            filteredUnits: [],
            itemsPerPage: -1,
            loading: {
                addBundle: false,
                warehouseStock: false,
                filterResults: false,
                filter: {
                    claim: false,
                    species: false,
                    suppliers: false,
                    products: false
                }
            },
            piecesTitle: this.$t('message.pcs'),
            resettingSalesOrderItems: false,
            searchTerm: null,
            selectedBundleIndex: null,
            // selectedBundleIndexes: [],
            selectStockDialog: false,
            stockOrderCurrencyId: 2,
            tableOptions: {
                page: 1
            },
            WarehouseStocks: []
        }
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('salesorder', {
            SalesOrder__currency_id: 'SalesOrder.currency_id',
            SalesOrder__gstpercent: 'SalesOrder.gstpercent',
            SalesOrder__gstrmrate: 'SalesOrder.gstrmrate',
            SalesOrder__id: 'SalesOrder.id',
            SalesOrder__issigned: 'SalesOrder.issigned',
            SalesOrder__ms_id: 'SalesOrder.ms_id',
            SalesOrder__office_id: 'SalesOrder.office_id',
            SalesOrder__orderstatus_id: 'SalesOrder.orderstatus_id',
            SalesOrder__product_type: 'SalesOrder.product_type'
        },'statePrefix'),
        ...mapFields('salesorder',{
            newSalesOrderSaved: 'newSalesOrderSaved',
            newSalesOrderUnsaved: 'newSalesOrderUnsaved',
            updatedSalesOrderSaved: 'updatedSalesOrderSaved',
            updatedSalesOrderUnsaved: 'updatedSalesOrderUnsaved'
        }),
        ...mapMultiRowFieldsPrefixed('salesorderitem',{
            SalesOrderItems: 'SalesOrderItems',
        },'statePrefix'),
        ...mapFieldsPrefixed('salesorderitem',{
            itemsTotalAmount: 'itemsTotalAmount',
            itemsTotalPieces: 'itemsTotalPieces',
            itemsTotalQty: 'itemsTotalQty',
            itemsTotalSheets: 'itemsTotalSheets'
        },'statePrefix'),
        ...mapGetters('certification',{
            allCertifications: 'allCertifications'
        }),
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapGetters('grade',{
            allGrades: 'allGrades',
            findGradeById: 'findGradeById'
        }),
        ...mapGetters('mc', {
            allMoistureContent: 'allMoistureContent'
        }),
        ...mapGetters('productgroup',{
            allProductGroups: 'allProductGroups'
        }),
        ...mapGetters('size',{
            allSizes: 'allSizes',
            findSizeById: 'findSizeById'
        }),
        ...mapGetters('species', {
            allSpecies: 'allSpecies',
            findSpeciesById: 'findSpeciesById'
        }),
        ...mapGetters('spec',{
            allSpecs: 'allSpecs',
            allCleanedSpecs: 'allCleanedSpecs',
            findSpecById: 'findSpecById'
        }),
        ...mapGetters('thickness', {
            allThickness: 'allThickness'
        }),
        ...mapGetters([
            'offices',
            'uofmDimensions',
            'uofmQty',
            'validationRules'
        ]),
        selectedItems() {
            let indexArray = []
            this.allSelectedItems.forEach(item => {
                indexArray.push(item.index)
            })
            return indexArray
        },
        showSelect() {
            return this.editByDialog && parseInt(this.SalesOrder__issigned) != 1
        },
        someItemsSelected() {
            return this.allSelectedItems.length > 0 && this.allSelectedItems.length < this.SalesOrderItems.length
        },
        stockOrderCurrency(){
            let code = this.allCurrencies.find((currency) => parseInt(currency.Currency.id) == parseInt(this.SalesOrder__currency_id))?.Currency?.code
            return code !== undefined ? code : ''
        },
        currencyFormatPrecision () {
            return this.allCurrencies.find((currency) => parseInt(currency.Currency.id) === parseInt(this.SalesOrder__currency_id))?.Currency?.formatPrecision
        },
        headers(){
            return [
                {
                    id: 1,
                    text: this.$t('message.lineNo'),
                    value: 'lineNumber'
                },
                {
                    id: 2,
                    text: this.bundleTitle,
                    value: 'Stock.bundleno'
                },
                {
                    id: 3,
                    text: this.$t('message.product'),
                    value: 'Stock.productgroup_id'
                },
                {
                    id: 4,
                    text: this.$t('message.size'),
                    value: 'Stock.size_id'
                },
                {
                    id: 5,
                    text: '',
                    value: 'data-table-select'
                },
                {
                    id: 6,
                    text: this.costPriceTitle,
                    class: 'force-text-right',
                    value: 'Stock.costprice'
                },
                {
                    id: 7,
                    text: this.$t('message.salesPrice'),
                    class: 'force-text-right',
                    value: 'Stock.sellingprice'
                },
                {
                    id: 8,
                    text: this.$t('message.amount'),
                    class: 'force-text-right',
                    value: 'Stock.amount'
                },
                {
                    id: 9,
                    text: this.$t('message.actions'),
                    value: 'Stock.id'
                },
            ]
        },
        Office__currency_id () {
            let office = this.offices.find(o => parseInt(o.Office.id) === parseInt(this.SalesOrder__office_id))
            return office.Office.currency_id
        },
        stockHeaders () {
            return [
                {
                    id: 1,
                    text: this.$t('message.po'),
                    value: 'Stock.StockOrder.title',
                    class: 'grey lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 2,
                    text: this.$t('message.supplier'),
                    value: 'Stock.StockOrder.Supplier.code',
                    class: 'grey lighten-3 pa-1',
                    sortable: true,
                    searchable: false
                },
                {
                    id: 3,
                    text: this.$t('message.etaWh'),
                    value: 'Stock.StockOrder.stockindate',
                    class: 'grey lighten-3 pa-1',
                    sortable: true,
                    searchable: false
                },
                {
                    id: 4,
                    text: this.$t('message.daysInStock'),
                    value: 'Stock.age',
                    class: 'grey lighten-3 pa-1',
                    sortable: true,
                    searchable: false
                },
                {
                    id: 5,
                    text: this.$t('message.claim'),
                    value: 'Stock.certification',
                    class: 'grey lighten-3 pa-1',
                    sortable: true,
                    searchable: false
                },
                {
                    id: 6,
                    text: this.bundleTitle,
                    value: 'Stock.bundleno',
                    class: 'grey lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 7,
                    text: this.$t('message.product'),
                    value: 'Product.title',
                    class: 'grey lighten-3 pa-1',
                    sortable: true,
                    searchable: false
                },
                {
                    id: 8,
                    text: this.$t('message.species'),
                    value: 'Species.title',
                    class: 'grey lighten-3 pa-1',
                    sortable: true,
                    searchable: false
                },
                {
                    id: 9,
                    text: this.$t('message.grade'),
                    value: 'Grade.title',
                    class: 'grey lighten-3 pa-1',
                    sortable: true,
                    searchable: false
                },
                {
                    id: 10,
                    text: 'T',
                    value: 'Stock.thickness',
                    class: 'grey lighten-3 pa-1',
                    sortable: true,
                    searchable: false
                },
                {
                    id: 11,
                    text: 'W',
                    value: 'Stock.length',
                    class: 'grey lighten-3 pa-1',
                    sortable: true,
                    searchable: false
                },
                {
                    id: 12,
                    text: 'L',
                    value: 'Stock.length',
                    class: 'grey lighten-3 pa-1',
                    sortable: true,
                    searchable: false
                },
                {
                    id: 13,
                    text: this.piecesTitle,
                    value: 'Stock.piece',
                    class: 'grey lighten-3 pa-1 text-no-wrap text-right',
                    sortable: true,
                    searchable: false
                },
                {
                    id: 14,
                    text: this.$t('message.quantity'),
                    value: 'Stock.volume',
                    class: 'grey lighten-3 pa-1 text-no-wrap text-right',
                    sortable: true,
                    searchable: false
                },
                {
                    id: 15,
                    text: this.$t('message.unit'),
                    value: 'Stock.StockOrder.Quantity.uofm',
                    class: 'grey lighten-3 pa-1 text-no-wrap text-right',
                    sortable: false,
                    searchable: false
                }
            ]
        },
        someSalesOrderData () {
            return {
                SalesOrder: {
                    id: this.SalesOrder__id,
                }
            }
        }
    },
    methods: {
        ...mapActions('salesorderitem',{
            addOtherChargesItemRow: 'addOtherChargesItemRow',
            addSalesOrderItemRows: 'addSalesOrderItemRows',
            deleteSalesOrderItem: 'deleteSalesOrderItem',
            resetSalesOrderItems: 'resetSalesOrderItems',
            updateTotals: 'updateTotals'
        }),
        ...mapActions('certification', {
            getAllCertifications: 'getAllCertifications'
        }),
        ...mapActions('currency', {
            getAllCurrencies: 'getAllCurrencies'
        }),
        ...mapActions('grade', {
            getAllGrades: 'getAllGrades'
        }),
        ...mapActions('mc', {
            getAllMoistureContent: 'getAllMoistureContent'
        }),
        ...mapActions('productgroup', {
            getAllProductGroups: 'getAllProductGroups'
        }),
        ...mapMutations('salesorderitem',{
            deleteSalesOrderItemRow: 'deleteSalesOrderItemRow',
        }),
        ...mapActions('size', {
            getAllSizes: 'getAllSizes'
        }),
        ...mapActions('species', {
            getAllSpecies: 'getAllSpecies'
        }),
        ...mapActions('spec', {
            getAllSpecs: 'getAllSpecs',
            getAllCleanedSpecs: 'getAllCleanedSpecs'
        }),
        ...mapActions('thickness', {
            getAllThickness: 'getAllThickness'
        }),
        addBundlesToSalesOrder () {
            let payload = {
                bundles: this.allSelectedBundles,
                prefix: this.statePrefix,
                salesOrder: this.someSalesOrderData
            }
            this.addSalesOrderItemRows(payload)
                .then(() => {
                    this.updateTotals( this.statePrefix )
                        .then(() => {
                            if(this.editByDialog){
                                this.$toast.success(this.$t('message.successes.bundlesAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.addBundle = false
                                let reloadList = false
                                if(this.updateMode) reloadList = true
                                    this.$emit('reload-item-list', {reloadList: reloadList})
                            } else {
                                this.updateEmptyOptions()
                                    .then(() => {
                                        this.updateEmptySizes()
                                            .then(() => {
                                                this.updateEmptySpecifications()
                                                    .then(() => {
                                                        this.$toast.success(this.$t('message.successes.bundlesAdded'),
                                                            {
                                                                classes: ['icon-float-left'],
                                                                icon: 'error_outline'
                                                            }
                                                        )
                                                        this.loading.addBundle = false
                                                    })
                                                    .catch(() => {
                                                        this.$toast.error(this.$t('message.errors.bundleCannotBeAdded'),
                                                            {
                                                                classes: ['icon-float-left'],
                                                                icon: 'error_outline'
                                                            }
                                                        )
                                                        this.loading.addBundle = false
                                                    })
                                            })
                                            .catch(() => {
                                                this.$toast.error(this.$t('message.errors.bundleCannotBeAdded'),
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                                this.loading.addBundle = false
                                            })
                                    })
                                    .catch(() => {
                                        this.$toast.error(this.$t('message.errors.bundleCannotBeAdded'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                        this.loading.addBundle = false
                                    })
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.bundleCannotBeAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.addBundle = false
                        })
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.bundleCannotBeAdded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.addBundle = false
                })
        },
        editBundleDialogClosed () {
            this.editBundleDialog = false;
        },
        editPricesDialogClosed () {
            this.editPricesDialog = false;
        },
        bundleUpdated (value) {
            this.editBundleDialog = false;
            this.$emit('reload-item-list', value)
        },
        bundlePriceUpdated (value) {
            this.editPricesDialog = false;
            this.$emit('reload-item-list', value)
        },
        checkIfSelected (stockId) {
            let selected = false
            this.SalesOrderItems.forEach(item => {
                if(parseInt(item.Stock.id) === parseInt(stockId)) selected = true
            })
            return selected
        },
        getCurrencyCode (currency_id) {
            let code = this.allCurrencies.find((currency) => parseInt(currency.Currency.id) === parseInt(currency_id))?.Currency?.code
            return code !== undefined ? code : ''
        },
        async deleteItem (itemIndex, itemId) {
            if (await this.$root.$confirm(this.$t('message.delete'), this.$t('message.confirmations.continueDeleteItemAction'), {color: 'red'})) {
                const promise = new Promise((resolve, reject) => {
                    try {
                        const payload = {
                            index: itemIndex,
                            prefix: this.statePrefix
                        }
                        this.deleteSalesOrderItemRow(payload)
                        resolve('ok')
                    } catch(error) {
                        reject(error)
                    }
                });
                promise
                    .then(() => {
                        if(itemId != null){
                            this.deleteSalesOrderItem({
                                salesOrderId: this.SalesOrder__id,
                                itemId: itemId}
                            )
                                .then((response)=>{
                                    this.updateTotals( this.statePrefix )
                                })
                        } else {
                            this.updateTotals( this.statePrefix )
                        }
                    })
            }
        },
        filterGradesByProduct(productId){
            let filteredGrades = this.allGrades.filter(grade => {
                return grade.Grade.productgroups.includes(productId)
            })
            if(filteredGrades.length > 0) {
                return filteredGrades
            } else {
                return []
            }
        },
        filterSpeciesByProduct(productId){
            let filteredSpecies = this.allSpecies.filter(species => {
                return species.Species.productgroups.includes(productId)
            })
            if (filteredSpecies.length > 0) {
                return filteredSpecies
            } else {
                return []
            }
        },
        filterSpecsByProduct(productId){
            let filteredSpecs = this.allCleanedSpecs.filter(spec => {
                return spec.Spec.productgroups.includes(productId)
            })
            if(filteredSpecs.length > 0) {
                return filteredSpecs
            } else {
                return []
            }
        },
        filterThickness(ms) {
            let various = this.allThickness.find(t => {
                return parseInt(t.Thickness.ms) === parseInt(ms) && t.Thickness.title === 'various'
            })
            let thicknesses = this.allThickness.filter(t => {
                return parseInt(t.Thickness.ms) === parseInt(ms) && t.Thickness.title !== 'various'
            })
            thicknesses.sort((a,b) => {
                let strA = a + ''
                let strB = b + ''
                return strA.localeCompare(strB, undefined, { numeric: true })
            })
            if(various){
                this.filteredThickness = [various,...thicknesses]
            } else {
                this.filteredThickness = thicknesses
            }
        },
        filterResults () {
            this.loading.filterResults = true
            this.tableOptions.page = 1
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        findCurrencyPrecision (val) {
            let currency = this.allCurrencies.find(c => parseInt(c.Currency.id) === parseInt(val))
            return currency?.Currency?.formatPrecision
        },
        findCertificationValue (certificationId) {
            let value = ''
            const certification = this.allCertifications.find(c => parseInt(c.Certification.id) === parseInt(certificationId))
            if(certification){
                value = certification.Certification.title
            }
            return value
        },
        findGradeValue (gradeId) {
            let value = ''
            const grade = this.allGrades.find(g => parseInt(g.Grade.id) === parseInt(gradeId))
            if(grade){
                value = grade.Grade.title
            }
            return value
        },
        findProductValue (productId) {
            let value = ''
            const productGroup = this.allProductGroups.find(p => parseInt(p.ProductGroup.id) === parseInt(productId))
            if(productGroup){
                value = productGroup.ProductGroup.title
            }
            return value
        },
        findSpeciesValue (speciesId) {
            let value = ''
            const species = this.allSpecies.find(s => parseInt(s.Species.id) === parseInt(speciesId))
            if(species){
                value = species.Species.title
            }
            return value
        },
        filterList(column = null, value = []) {
            return new Promise((resolve, reject) => {
                try {
                    this.filterValues[column] = value
                    let filterFunctions = []
                    for (const field in this.filterValues) {
                        if (this.filterValues[field] != null && this.filterValues[field].length > 0) {
                            filterFunctions.push((item) => {
                                if(column === 'claim') return this.filterValues[field].includes(item['Stock']['certification'])
                                if(column === 'product') return this.filterValues[field].includes(item['Product']['title'])
                                if(column === 'species') return this.filterValues[field].includes(item['Species']['title'])
                                if(column === 'supplier') return this.filterValues[field].includes(item['Stock']['StockOrder']['Supplier']['code'])
                            })
                        }
                    }
                    if (filterFunctions.length > 0) {
                        this.filteredWarehouseStocks = this.WarehouseStocks.filter(item => filterFunctions.every(f => f(item)))
                    } else {
                        this.filteredWarehouseStocks = this.WarehouseStocks
                    }
                    resolve('done')
                } catch (e) {
                    reject(e)
                }
            })
        },
        formatDate,
        formatSize (item) {
            let size = [];
            if(item.Stock.thick?.length > 0) size.push(item.Stock.thick + (item.Stock.Thickness.uofm?.length > 0 ? " " + item.Stock.Thickness.uofm : ''))
            if(item.Stock.width?.length > 0) size.push(item.Stock.width + (item.Stock.Width.uofm?.length > 0 ? " " + item.Stock.Width.uofm : ''))
            if(item.Stock.length?.length > 0) size.push(item.Stock.length + (item.Stock.Length.uofm?.length > 0 ? " " + item.Stock.Length.uofm : ''))
            return size.join(' x ')
        },
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        getThickness (thicknessId) {
            let thickness = ''
            const obj = this.filteredThickness.find(t => parseInt(t.Thickness.id) === parseInt(thicknessId))
            if(obj){
                thickness = obj.Thickness?.title
            }
            return thickness
        },
        getUiDescription(item) {
            let uiDescription = []

            const species = this.allSpecies.find(s => parseInt(s.Species.id) === parseInt(item.Stock.species_id))
            if(species) uiDescription.push(species.Species.title)

            if(item.Stock.description != null) {
                let product = this.allProductGroups.find(p => parseInt(p.ProductGroup.id) === parseInt(item.Stock.productgroup_id))
                if (product && item.Stock.description.includes(product.ProductGroup.title) === false) uiDescription.push(product.ProductGroup.title)
            } else {
                let product = this.allProductGroups.find(p => parseInt(p.ProductGroup.id) === parseInt(item.Stock.productgroup_id))
                if (product) uiDescription.push(product.ProductGroup.title)
            }

            if(item.Stock.description !== '') uiDescription.push(item.Stock.description)

            // const grade = this.allGrades.find(g => g.Grade.id == item.Stock.grade_id)
            // if(grade) uiDescription.push(grade.Grade.title)

            let certification = this.allCertifications.find(c => parseInt(c.Certification.id) === parseInt(item.Stock.certification_id))
            if(certification && item.Stock.description != null && item.Stock.description.includes(certification.Certification.title) === false && certification.Certification.title !== 'None') uiDescription.push(certification.Certification.title)
            if(certification && item.Stock.description == null && certification.Certification.title !== 'None') uiDescription.push(certification.Certification.title)

            const mc = this.allMoistureContent.find(mc => parseInt(mc.Mc.id) === parseInt(item.Stock.mc_id))
            if(mc && item.Stock.description == null) uiDescription.push(mc.Mc.title)

            uiDescription = uiDescription.filter(Boolean).join(", ")

            this.$store.commit('salesorderitem/updateSalesOrderItems', {
                index: item.index,
                field: 'Stock.salesdescription',
                prefix: this.statePrefix,
                value: uiDescription
            })
            return uiDescription
        },
        getWarehouseStock () {
            const office = this.offices.find( o => parseInt(o.Office.id) === parseInt(this.SalesOrder__office_id))
            return new Promise ((resolve, reject) => {
                this.loading.warehouseStock = true
                api
                    .get('/stocks',{
                        params: {
                            conditions: [
                                {
                                    field: 'stockorders.stock_country_id',
                                    value: office.Office.country_id
                                },
                                {
                                    field: 'stocks.stockstate_id',
                                    value: 3
                                },
                                {
                                    field: 'stocks.type',
                                    value: this.SalesOrder__product_type === 1 ? 'NSV' : 'SV'
                                }
                            ],
                            order: [
                                {
                                    field: 'species.title',
                                    direction: 'ASC'
                                },
                                {
                                    field: 'stocks.bundleno',
                                    direction: 'ASC'
                                }
                            ],
                            do_no_calculate_prices: true
                        }
                    })
                    .then( response => {
                        this.WarehouseStocks = response.data.data
                        this.tableOptions.page = 1
                        resolve('done')
                    })
                    .catch( error => {
                        reject(error)
                    })
                    .finally(() => {
                        this.loading.warehouseStock = false
                    })
            })
        },
        loadFilterOptions() {
            return new Promise((resolve, reject) => {
                try {
                    const claims = []
                    const products = []
                    const species = []
                    const suppliers = []
                    this.WarehouseStocks.forEach(stock => {
                        if(stock.Stock.StockOrder.Supplier.code != null){
                            suppliers.push(stock.Stock.StockOrder.Supplier.code)
                        }
                        if(stock.Species.title != null){
                            species.push(stock.Species.title)
                        }
                        if(stock.Product.title != null){
                            products.push(stock.Product.title)
                        }
                        if(stock.Stock.certification != null){
                            claims.push(stock.Stock.certification)
                        }
                    })
                    this.filterOptions.suppliers = [... new Set(suppliers)]
                    this.filterOptions.claims = [... new Set(claims)]
                    this.filterOptions.species = [... new Set(species)]
                    this.filterOptions.products = [... new Set(products)]
                    this.filterOptions.suppliers.sort()
                    this.filterOptions.claims.sort()
                    this.filterOptions.species.sort()
                    this.filterOptions.products.sort()

                    resolve('done')
                } catch(e) {
                    reject(e)
                }
            })
        },
        openEditBundleDialog(itemIndex) {
            this.selectedBundleIndex = itemIndex
            this.editBundleDialog = true
        },
        openEditPricesDialog() {
            if(this.allSelectedItems.length > 0) {
                this.editPricesDialog = true
            } else {
                this.$toast.error(this.$t('message.errors.noBundlesSelected'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        openSelectStockDialog () {
            this.selectStockDialog = true
            this.allSelectedBundles = []
            if(this.WarehouseStocks.length === 0 ) {
                this.getWarehouseStock()
                    .then(() => {
                        this.loadFilterOptions()
                            .then(() => {
                                this.filteredWarehouseStocks = this.WarehouseStocks
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('message.errors.cannotLoadStocks'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            })
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.cannotLoadStocks'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            } else {
                this.loadFilterOptions()
                    .then(() => {
                        console.log('options loaded!')
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.cannotLoadOptions'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
                this.filteredWarehouseStocks = this.WarehouseStocks
            }
        },
        resetFilters () {
            this.filterValues.product = null
            this.filterValues.supplier = null
            this.filterValues.species = null
            this.filterValues.claim = null
            this.filteredWarehouseStocks = this.WarehouseStocks
        },
        async resetItems () {
            if (await this.$root.$confirm(this.$t('message.resetSalesOrderItems'), this.$t('message.confirmations.continueSalesOrderItemsReset'), {color: 'orange'})) {
                this.resettingSalesOrderItems = true
                this.resetSalesOrderItems(this.statePrefix)
                    .then(() => {
                        this.resettingSalesOrderItems = false
                        this.WarehouseStocks = []
                    })
            }
        },
        setItemDescription(idx) {
            if(idx != null) {
                let product = null
                let spec = null
                let mc = null

                let productGroupId = this.SalesOrderItems[idx].Stock.productgroup_id
                let specId = this.SalesOrderItems[idx].Stock.spec_id
                let mcId = this.SalesOrderItems[idx].Stock.mc_id

                let productObj = this.allProductGroups.find(pg => parseInt(pg.ProductGroup?.id) === parseInt(productGroupId))
                let specObj = this.allCleanedSpecs.find(s => parseInt(s.Spec.id) === parseInt(specId))
                if(specId === 'NEW'){
                    specObj = { Spec: this.SalesOrderItems[idx]['Spec'] }
                }
                if(typeof specObj === 'undefined'){
                    specObj = { Spec: this.SalesOrderItems[idx]['Spec'] }
                }
                let mcObj = this.allMoistureContent.find(mc => parseInt(mc.Mc.id) === parseInt(mcId))

                if(productObj) product = productObj?.Productgroup?.title
                if(specObj && specObj?.Spec?.title !== 'na') spec = specObj?.Spec?.title
                if(mcObj) mc = mcObj?.Mc?.title

                let description = generateSpec(product, spec, mc)
                this.SalesOrderItems[idx].Stock.description = description.trim()
            }
        },
        toggleAllSelect () {
            this.allItemsSelected = !this.allItemsSelected
            if(this.allItemsSelected){
                this.allSelectedItems = this.SalesOrderItems
            } else {
                this.allSelectedItems = []
            }
        },
        uofmPrecision (uofm) {
            if(uofm) {
                if(isNaN(uofm)) {
                    return this.uofmQty.find((u) => u.Measurement.title === uofm.trim())?.Measurement?.formatPrecision
                } else {
                    return this.uofmQty.find((u) => parseInt(u.Measurement.id) === parseInt(uofm))?.Measurement?.formatPrecision
                }
            } else {
                return '0,0.00'
            }
        },
        updateEmptyOptions() {
            return new Promise((resolve, reject) => {
                try {
                    this.SalesOrderItems.forEach((item) => {
                        const productId = item.Stock.productgroup_id
                        const idx = item.index

                        if(item.Options.species.length === 0) {
                            //filter species
                            const species = this.filterSpeciesByProduct(productId)
                            const speciesPayload = {
                                index: idx,
                                field: 'Options.species',
                                prefix: this.statePrefix,
                                value: species
                            }
                            this.$store.commit('salesorderitem/updateSalesOrderItems', speciesPayload)
                        }

                        if(item.Options.species.length === 0) {
                            //filter grades
                            const grades = this.filterGradesByProduct(productId)
                            const gradesPayload = {
                                index: idx,
                                field: 'Options.grades',
                                prefix: this.statePrefix,
                                value: grades
                            }
                            this.$store.commit('salesorderitem/updateSalesOrderItems', gradesPayload)
                        }

                        if(item.Options.species.length === 0) {
                            //filter specs
                            const specs = this.filterSpecsByProduct(productId)
                            const specsPayload = {
                                index: idx,
                                field: 'Options.specs',
                                prefix: this.statePrefix,
                                value: specs
                            }
                            this.$store.commit('salesorderitem/updateSalesOrderItems', specsPayload)
                        }
                    })
                    log('Updated empty options')
                    resolve('done')
                } catch(error) {
                    reject(error)
                }
            })
        },
        updateEmptySizes() {
            return new Promise((resolve, reject) => {
                try {
                    this.SalesOrderItems.forEach((item) => {
                        const idx = item.index
                        //update size_text
                        let thicknessId = this.SalesOrderItems[idx].Stock.thickness_id
                        let width = this.SalesOrderItems[idx].Stock.width
                        let length = this.SalesOrderItems[idx].Stock.length

                        const thicknessObj = this.allThickness.find(t => parseInt(t.Thickness.id) === parseInt(thicknessId))
                        const thickness = thicknessObj?.Thickness.title
                        let unit = thicknessObj?.Thickness.suffix
                        if(unit == null){
                            unit = ''
                        }

                        const sizeText = generateDimension(thickness,width,length,unit)

                        if(item.Stock.size !== sizeText) {
                            this.$store.commit('salesorderitem/updateSalesOrderItems', {
                                index: idx,
                                field: 'Stock.size',
                                prefix: this.statePrefix,
                                value: sizeText
                            })

                            //update Size object
                            //check if size exists
                            let sizeId = null
                            let sizeObj = this.allSizes.find(s => s.Size.title === sizeText)
                            if (sizeObj) {
                                sizeId = sizeObj?.Size.id
                            } else {
                                if (sizeText.length > 0) {
                                    sizeId = 'NEW' //new size object
                                }
                            }
                            this.$store.commit('salesorderitem/updateSalesOrderItems', {
                                index: idx,
                                field: 'Size.id',
                                prefix: this.statePrefix,
                                value: sizeId
                            })
                            this.$store.commit('salesorderitem/updateSalesOrderItems', {
                                index: idx,
                                field: 'Size.title',
                                prefix: this.statePrefix,
                                value: sizeText
                            })
                            this.$store.commit('salesorderitem/updateSalesOrderItems', {
                                index: idx,
                                field: 'Stock.size_id',
                                prefix: this.statePrefix,
                                value: sizeId
                            })
                        }
                    })
                    log('Updated empty sizes')
                    resolve('done')
                } catch (error){
                    reject(error)
                }
            })
        },
        updateEmptySpecifications() {
            return new Promise((resolve, reject) => {
                try {
                    this.SalesOrderItems.forEach((item) => {
                        const idx = item.index
                        const specObj = this.allSpecs.find(s => parseInt(s.Spec.id) === parseInt(item.Stock.spec_id))
                        // assign Spec property in SalesOrderItem
                        this.$store.commit('salesorderitem/updateSalesOrderItems', {
                            index: idx,
                            field: 'Spec.id',
                            prefix: this.statePrefix,
                            value: specObj?.Spec?.id
                        })
                        this.$store.commit('salesorderitem/updateSalesOrderItems', {
                            index: idx,
                            field: 'Spec.title',
                            prefix: this.statePrefix,
                            value: specObj?.Spec?.title
                        })
                    })
                    log('Updated empty specs')
                    resolve('done')
                } catch (error) {
                    reject(error)
                }
            })
        },
        updateSalesOrderItemRowHandle (idx,field,value) {
            switch(field) {
                case 'Stock.spec_id':
                    if(value) {
                        if(value?.Spec?.id) { //value is an object
                            payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: value?.Spec?.id
                            }
                            // assign Spec property in SalesOrderItem
                            this.$store.commit('salesorderitem/updateSalesOrderItems', {
                                index: idx,
                                field: 'Spec.id',
                                prefix: this.statePrefix,
                                value: value?.Spec?.id
                            })
                            this.$store.commit('salesorderitem/updateSalesOrderItems', {
                                index: idx,
                                field: 'Spec.title',
                                prefix: this.statePrefix,
                                value: value?.Spec?.title
                            })

                            //update Stock.mc_id if empty and Spec.mc_id is not - VJC @ 2021-08-06
                            let specObj = this.allSpecs.find(s => parseInt(s.Spec.id) === parseInt(value?.Spec?.id))
                            if(specObj && specObj?.Spec.mc_id != null) {
                                this.SalesOrderItems[idx]['Stock']['mc_id'] = specObj?.Spec?.mc_id
                            }

                        } else {
                            //value is a string - new spec
                            payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: 'NEW'
                            }
                            // assign Spec property in Stock
                            this.$store.commit('salesorderitem/updateSalesOrderItems', {
                                index: idx,
                                field: 'Spec.id',
                                prefix: this.statePrefix,
                                value: 'NEW'
                            })
                            this.$store.commit('salesorderitem/updateSalesOrderItems', {
                                index: idx,
                                field: 'Spec.title',
                                prefix: this.statePrefix,
                                value: value
                            })
                        }
                    } else {
                        //if empty
                        payload = {
                            index: idx,
                            field: field,
                            prefix: this.statePrefix,
                            value: null
                        }
                        // assign Spec property in NcrItem
                        this.$store.commit('salesorderitem/updateSalesOrderItems', {
                            index: idx,
                            field: 'Spec.id',
                            prefix: this.statePrefix,
                            value: null
                        })
                        this.$store.commit('salesorderitem/updateSalesOrderItems', {
                            index: idx,
                            field: 'Spec.title',
                            prefix: this.statePrefix,
                            value: null
                        })
                    }
                    break;
                case 'Stock.productgroup_id':
                    if(typeof value === 'undefined') value = null
                    payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value,
                    }
                    //filter species
                    const species = this.filterSpeciesByProduct(value)
                    const speciesPayload = {
                        index: idx,
                        field: 'Options.species',
                        prefix: this.statePrefix,
                        value: species
                    }
                    this.$store.commit('salesorderitem/updateSalesOrderItems', speciesPayload)

                    //filter grades
                    const grades = this.filterGradesByProduct(value)
                    const gradesPayload = {
                        index: idx,
                        field: 'Options.grades',
                        prefix: this.statePrefix,
                        value: grades
                    }
                    this.$store.commit('salesorderitem/updateSalesOrderItems', gradesPayload)

                    //filter specs
                    const specs = this.filterSpecsByProduct(value)
                    const specsPayload = {
                        index: idx,
                        field: 'Options.specs',
                        prefix: this.statePrefix,
                        value: specs
                    }
                    this.$store.commit('salesorderitem/updateSalesOrderItems', specsPayload)
                    break;
                case 'Stock.soldmeasurement_id':
                    var payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value,
                    }
                    //assign Soldmeasurement property in Stock
                    let measurement = this.uofmQty.filter(uofm => parseInt(uofm.Measurement.id) === parseInt(value))[0];
                    if(measurement != null) {
                        this.$store.commit('salesorderitem/updateSalesOrderItems', {
                            index: idx,
                            field: 'Soldmeasurement.id',
                            prefix: this.statePrefix,
                            value: measurement.Measurement.id != null ? measurement.Measurement.id : null
                        })
                        this.$store.commit('salesorderitem/updateSalesOrderItems', {
                            index: idx,
                            field: 'Soldmeasurement.title',
                            prefix: this.statePrefix,
                            value: measurement.Measurement.title != null ? measurement.Measurement.title : null
                        })
                    }
                    break;
                case 'Stock.species_id':
                    var payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value?.Species?.id
                    }
                    // assign Species property in Stock
                    this.$store.commit('salesorderitem/updateSalesOrderItems', {
                        index: idx,
                        field: 'Species.id',
                        prefix: this.statePrefix,
                        value: value?.Species?.id
                    })
                    this.$store.commit('salesorderitem/updateSalesOrderItems', {
                        index: idx,
                        field: 'Species.title',
                        prefix: this.statePrefix,
                        value: value?.Species?.title
                    })
                    break;
                case 'Stock.grade_id':
                    var payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value?.Grade?.id
                    }
                    // assign Grade property in Stock
                    this.$store.commit('salesorderitem/updateSalesOrderItems', {
                        index: idx,
                        field: 'Grade.id',
                        prefix: this.statePrefix,
                        value: value?.Grade?.id
                    })
                    this.$store.commit('salesorderitem/updateSalesOrderItems', {
                        index: idx,
                        field: 'Grade.title',
                        prefix: this.statePrefix,
                        value: value?.Grade?.title
                    })
                    break;
                case 'Stock.thickness_id':
                case 'Stock.width':
                case 'Stock.length':
                    if(typeof value === 'undefined') value = null

                    //update size_text
                    let thicknessId = this.SalesOrderItems[idx].Stock.thickness_id
                    let width = this.SalesOrderItems[idx].Stock.width
                    let length = this.SalesOrderItems[idx].Stock.length
                    if(field === 'Stock.thickness_id'){
                        thicknessId = value
                    }
                    if(field === 'Stock.width'){
                        width = value
                    }
                    if(field === 'Stock.length'){
                        length = value
                    }

                    const thicknessObj = this.allThickness.find(t => parseInt(t.Thickness.id) === parseInt(thicknessId))
                    const thickness = thicknessObj?.Thickness.title
                    let unit = thicknessObj?.Thickness.suffix
                    if(unit == null){
                        unit = ''
                    }

                    const sizeText = generateDimension(thickness,width,length,unit)

                    this.$store.commit('salesorderitem/updateSalesOrderItems', {
                        index: idx,
                        field: 'Stock.size',
                        prefix: this.statePrefix,
                        value: sizeText
                    })

                    //update Size object
                    //check if size exists
                    let sizeId = null
                    let sizeObj = this.allSizes.find(s => parseInt(s.Size.title) === parseInt(sizeText))
                    if(sizeObj){
                        sizeId = sizeObj?.Size.id
                    } else {
                        if (sizeText.length > 0) {
                            sizeId = 'NEW' //new size object
                        }
                    }
                    this.$store.commit('salesorderitem/updateSalesOrderItems', {
                        index: idx,
                        field: 'Size.id',
                        prefix: this.statePrefix,
                        value: sizeId
                    })
                    this.$store.commit('salesorderitem/updateSalesOrderItems', {
                        index: idx,
                        field: 'Size.title',
                        prefix: this.statePrefix,
                        value: sizeText
                    })
                    this.$store.commit('salesorderitem/updateSalesOrderItems', {
                        index: idx,
                        field: 'Stock.size_id',
                        prefix: this.statePrefix,
                        value: sizeId
                    })
                    payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value,
                    }
                    // log(payload)
                    break;
                default:
                    var payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value,
                    }
            }
            const promise = new Promise((resolve, reject) => {
                try {
                    this.$store.commit('salesorderitem/updateSalesOrderItems', payload)
                    resolve('ok')
                } catch (error) {
                    reject(error)
                }
            });
            promise
                .then(()=>{
                    this.updateTotals(this.statePrefix)
                    if(['Stock.productgroup_id','Stock.spec_id','Stock.grade_id','Stock.mc_id'].includes(field)){
                        this.setItemDescription(idx)
                    }
                    log('Item updated...')
                })
        }
    },
    watch: {
        editByDialog(val) {
            if(val){
                localStorage.setItem('warehouseSalesEditByDialog','true')
            } else {
                localStorage.setItem('warehouseSalesEditByDialog','false')
            }
        },
        SalesOrder__office_id () {
            this.filteredWarehouseStocks = []
            this.WarehouseStocks = []
            this.filterOptions.claims = []
            this.filterOptions.products = []
            this.filterOptions.species = []
            this.filterOptions.suppliers = []
            // this.getWarehouseStock()
        },
        SalesOrder__product_type (val) {
            if(val === 1) {
                this.bundleTitle = this.$t('message.bundle')
                this.costPriceTitle = this.$t('message.costPrice')
                this.piecesTitle = this.$t('message.pcs')
            } else if(val === 2) {
                this.bundleTitle = this.$t('message.pallet')
                this.costPriceTitle = 'GAC'
                this.piecesTitle = this.$t('message.sheets')
            }
            this.WarehouseStocks = []
            // this.getWarehouseStock()
        }
    },
    created () {
        //check editByDialog
        if(localStorage.getItem('warehouseSalesEditByDialog')){
            if(localStorage.getItem('warehouseSalesEditByDialog') === 'true'){
                this.editByDialog = true
                this.itemsPerPage = -1
                this.disablePagination = true
            } else {
                if(this.SalesOrderItems.length > 5) {
                    this.editByDialog = true
                    this.itemsPerPage = -1
                    this.disablePagination = true
                } else {
                    this.editByDialog = false
                }
            }
        } else {
            localStorage.setItem('warehouseSalesEditByDialog','true')
            this.editByDialog = true
        }

        if(this.SalesOrderItems.length > 5 && this.editByDialog === false){
            this.disablePagination = false
            this.itemsPerPage = 5
        }
        if(this.allSpecies.length === 0) this.getAllSpecies()
        if(this.allGrades.length === 0) this.getAllGrades()
        // if(this.allCertifications.length == 0) this.getAllCertifications()
        // if(this.allProductGroups.length == 0) this.getAllProductGroups()
        if(this.allCurrencies.length === 0) this.getAllCurrencies()
        if(this.allMoistureContent.length === 0) this.getAllMoistureContent()
        if(this.allCleanedSpecs.length === 0) this.getAllCleanedSpecs()
        if(this.allSizes.length === 0) this.getAllSizes()
        if(this.allThickness.length === 0) this.getAllThickness()
        // if(this.updateMode === false) {
            if(this.SalesOrder__product_type === 1) {
                this.bundleTitle = this.$t('message.bundle')
                this.costPriceTitle = this.$t('message.costPrice')
                this.piecesTitle = this.$t('message.pcs')
            } else if(this.SalesOrder__product_type === 2) {
                this.bundleTitle = this.$t('message.pallet')
                this.costPriceTitle = 'GAC'
                this.piecesTitle = this.$t('message.sheets')
            }
            this.getWarehouseStock()
        // }
    },
    mounted() {
        this.$refs.salesOrderItemsForm.resetValidation()
        this.$store.watch(
            function (state) {
                return state.appic.salesorderitem.update.SalesOrderItems
            },
            () => {
                this.updatedSalesOrderUnsaved = true
            },
            {deep: true}
        )
        this.$store.watch(
            function (state) {
                return state.appic.salesorderitem.current.SalesOrderItems
            },
            () => {
                this.newSalesOrderUnsaved = true
            },
            {deep: true}
        )
        this.$nextTick(function () {
            this.filteredUnits = this.uofmQty.filter(u => {
                return parseInt(u.Measurement.system) === parseInt(this.SalesOrder__ms_id) || u.Measurement.system == null
            })
            this.filterThickness(this.SalesOrder__ms_id)
            if(this.updateMode && !this.editByDialog) {
                this.SalesOrderItems.forEach((item, idx) => {
                    //filter species
                    let species = this.filterSpeciesByProduct(item.Stock.productgroup_id)
                    let speciesPayload = {
                        index: idx,
                        field: 'Options.species',
                        prefix: this.statePrefix,
                        value: species,
                    }
                    this.$store.commit('salesorderitem/updateSalesOrderItems', speciesPayload)

                    //filter grades
                    let grades = this.filterGradesByProduct(item.Stock.productgroup_id)
                    let gradesPayload = {
                        index: idx,
                        field: 'Options.grades',
                        prefix: this.statePrefix,
                        value: grades,
                    }
                    this.$store.commit('salesorderitem/updateSalesOrderItems', gradesPayload)

                    //filter specs
                    let specs = this.filterSpecsByProduct(item.Stock.productgroup_id)
                    let specsPayload = {
                        index: idx,
                        field: 'Options.specs',
                        prefix: this.statePrefix,
                        value: specs,
                    }
                    this.$store.commit('salesorderitem/updateSalesOrderItems', specsPayload)
                })
            }
        })
    },
    updated() {
        this.filteredUnits = this.uofmQty.filter(u => {
            return parseInt(u.Measurement.system) === parseInt(this.SalesOrder__ms_id) || u.Measurement.system == null
        })
        this.filterThickness(this.SalesOrder__ms_id)
        this.$nextTick(function () {
            if(this.updateMode && !this.editByDialog) {
                this.SalesOrderItems.forEach((item, idx) => {
                    //filter species
                    let species = this.filterSpeciesByProduct(item.Stock.productgroup_id)
                    let speciesPayload = {
                        index: idx,
                        field: 'Options.species',
                        prefix: this.statePrefix,
                        value: species,
                    }
                    this.$store.commit('salesorderitem/updateSalesOrderItems', speciesPayload)

                    //filter grades
                    let grades = this.filterGradesByProduct(item.Stock.productgroup_id)
                    let gradesPayload = {
                        index: idx,
                        field: 'Options.grades',
                        prefix: this.statePrefix,
                        value: grades,
                    }
                    this.$store.commit('salesorderitem/updateSalesOrderItems', gradesPayload)

                    //filter specs
                    let specs = this.filterSpecsByProduct(item.Stock.productgroup_id)
                    let specsPayload = {
                        index: idx,
                        field: 'Options.specs',
                        prefix: this.statePrefix,
                        value: specs,
                    }
                    this.$store.commit('salesorderitem/updateSalesOrderItems', specsPayload)
                })
            }
        })
    }
}
</script>

<style>
.bg-lightgrey {
    background-color: #F5F5F5;
}
.v-text-field__slot input {
    text-align: right;
}
.custom.v-text-field {
    font-size: 0.75rem !important;
}
.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}
.v-data-table td {
    padding: 0 5px;
}
.font-xs {
    font-size: 0.65rem;
}
.custom .v-icon {
    font-size: 18px !important;
}
.truncate {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table tbody td {
    vertical-align: top !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    height: 40px !important;
    font-size: 0.75rem !important;
}
.row-reverse { flex-direction: row-reverse; }
.force-text-left {
    text-align: left !important;
}
.width-18-pct {
    width: 18%;
}
.v-data-table th.force-text-right {
    text-align: right !important;
}
.font-16px {
    font-size: 16px !important;
    line-height: 20px;
}
.dense-inputs .v-input {
    font-size: 16px !important;
}
.mr-10-selection {
    margin-right: 10px;
}
.warehouse-stocks .v-data-table-header tr th:first-child {
    padding-left: 2px !important;
    background-color: #EEEEEE !important;
}
.warehouse-stocks .v-data-table-header tr th:not(:first-child) {
    white-space: nowrap;
    border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
}
.filter-width-150 {
    width: 150px !important;
    max-width: 150px !important;
}
.input-box {
    background-color: #EEEEEE;
    padding-left: 5px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    min-height: 26px !important;
}
.width-30-pct {
    width: 30% !important;
}
.width-35-pct {
    width: 35% !important;
}
.width-15-pct {
    width: 15% !important;
}
.width-13-pct {
    width: 13% !important;
}
</style>